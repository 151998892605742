import React from 'react';
import { Link } from 'gatsby';

// import style
import '../style/index.scss';
// import SEO tool
import SEO from '../components/seo';
import Header from '../components/header';
import Footer from '../components/footer';

import { toAnt } from '../components/functions';

export default () => {
    return (<>
    <SEO title="help" />
    <div className="app">
        <Header />
        <div className="content">
            <h1>HELP</h1>
            <p>
            The basic app is rather straight forward. Just type in the upper text field, hit 
            <span className="capital-tracking"> 'TRANTSLATE'</span> and the trantslated text will appear in the lower field.
            You can even quickly copy the trantslated text to your clip board by tapping the <span className="capital-tracking">'COPY'</span> button!
            That said, the <span className="capital-tracking">'ANTVANCED OPTIONS'</span> may make less sense.
            </p>
            <p>
            The first option is spacing. This sets the spacing between words in the trantslated text. <span className="capital-tracking">'MONOSPACE' </span>
            inserts one space, <span className="capital-tracking">'DOUBLE SPACE'</span> inserts two.
            This is a bit of a carryover from an older version of the app, which would pad the letters in a word with spaces.
            Some ants used double spacing to help make different words clearer.
            However, in order to accomadate people who use screen readers, Ant Colony admins now discourage adding spaces between letters. Since I already
            built the code to add double spaces, I kept it in and decided to let to the user decide to use it or not.
            </p>
            <p>
            The second option makes the trantslated text more 'ant-ey'. I noticed some people will swap 'at', 'an', or 'ad' in a word with 'ant',
            and turning on this option is meant to do that. You may find results vary. That, and since when you swap 'ant' into words is a matter
            of personal taste, I made it so you can manually edit the trantslated text.
            </p>
        </div>
        <Footer />        
    </div>
    </>);
};